import axios from "axios";

export default {
    async getEvents() {
        const data = new FormData();
        data.append("limit", "5");
        data.append("offset", "0");
        data.append("orderby", "events_calendar_date_start");
        data.append("orderdir", "desc");

        const response = await axios.post(`rest/v1/search/events_calendar`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        return response.data.data;
    },

    async setPartecipazione(data: Record<string, any>) {
        const response = await axios.post(`rest/v1/create/events_calendar_participant`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    async checkAdesione(eventId: string, userId: string) {
        const data = new FormData();
        data.append("where[events_calendar_participant_event_id]", eventId);
        data.append("where[events_calendar_participant_user]", userId);
        data.append("where[events_calendar_participant_confirm]", "1");

        const response = await axios.post(`rest/v1/search/events_calendar_participant`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    async getAdesioni(eventId: string) {
        console.log(eventId);
        const data = new FormData();
        data.append("where[events_calendar_participant_event_id]", eventId);
        data.append("where[events_calendar_participant_confirm]", "1");

        const response = await axios.post(`rest/v1/search/events_calendar_participant`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response.data.data;
    },
};
